import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    id: null,
    newEmployee: true,
    hasChange: false,
    personalData: {
        type: '1',
        file64: '',
        status: 1,
        employee_type: 'BARBER'
    },
    equipaments: [],
    services: [],
    schendule: {
        monday: { enable: false },
        tuesday: { enable: false },
        wednesday: { enable: false },
        thursday: { enable: false },
        friday: { enable: false },
        saturday: { enable: false },
        sunday: { enable: false }
    }
};

export const employee = createSlice({
    name: 'employee',
    initialState: initialState,
    reducers: {
        changeNewEmployee: (state, action) => {
            state.newEmployee = action.payload;
        },
        setPersonalData: (state, action) => {
            state.personalData = action.payload;
            state.hasChange = true;
        },
        pushEquipament: (state, action) => {
            state.equipaments = [ ...state.equipaments, action.payload ];
            state.hasChange = true;
        },
        removeEquipament: (state, action) => {
            state.equipaments = state.equipaments.filter((equip, i) => i !== action.payload);
            state.hasChange = true;
        },
        pushService: (state, action) => {
            state.services = [ ...state.services, action.payload ];
            state.hasChange = true;
        },
        removeService: (state, action) => {
            state.services = state.services.filter((equip, i) => i !== action.payload);
            state.hasChange = true;
        },
        setSchendule: (state, action) => {
            state.schendule = action.payload;
            state.hasChange = true;
        },
        setEmployeeType: (state, action) => {
            state.personalData = {
                ...state.personalData,
                employee_type: action.payload
            };
        },
        setEmployeeId: (state, action) => {
            state.id = action.payload;
            state.newEmployee = action.payload === null;
        },
        resetForm: (state, action) => {
            state = initialState;
        },
        setEditEmployee: (state, action) => {
            const type = state.personalData.employee_type;

            state.personalData = action.payload.personalData;
            state.personalData.employee_type = type;
            state.services = action.payload.services;
            state.equipaments = action.payload.equipaments;
            state.schendule = action.payload.schendule;
            state.hasChange = false;
        }
    },
})

export const {
    resetForm,
    changeNewEmployee,
    setPersonalData,
    pushEquipament,
    removeEquipament,
    pushService,
    removeService,
    setSchendule,
    setEmployeeType,
    setEditEmployee,
    setEmployeeId
} = employee.actions

export default employee.reducer